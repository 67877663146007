import React, { Component } from 'react'
import Layout from '../../../components/layout';
import { Banner } from '../../../components/banner';
import { FormGroup, Col } from 'reactstrap'
import moment from 'moment'
import { Cache } from 'aws-amplify';
import { navigate } from '@reach/router'
import { isLoggedIn } from "../../../services/auth";
import { getIntegrationStatus, getVkeyAssetsDetails, getMAPHPPKeys, getSFTPDetails, getMidTidDetails  } from '../../../services/api'
import Merchantcard from '../../../containers/netsclick/merchantcard';
import { ENV_PROD, ENV_UAT } from '../../../utils/constants';

export default class netsClick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: true,
      selectedApp: null,
      integrationStatus: null,
      uatCredentialsInfo: null,
      prodCredentialsInfo: null,
      uatPreRequisiteDueDate: null,
      materialInfo: null,
      prodCrdentialCompletionStatus: 0,
      uatCrdentialCompletionStatus: 0,
      prodPreRequsitieDueDate : null,
      uatPreRequsitieDueDate : null
    }

  }
  componentDidMount() {
    if (!isLoggedIn()) {
      navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
      if (typeof window !== "undefined") {
        window.location.reload()
      }
    }
  }
  async componentWillMount() {
    this.setState({
      fetched : false
    })
    if (!isLoggedIn()) {
      navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
      if (typeof window !== "undefined") {
        window.location.reload()
      }
    }
    if (Cache.getItem("ma") != 1) {
      navigate(`/account/mfauth/`, { state: { netsClick: true } })
      if (typeof window !== "undefined") {
        window.location.reload()
      }
    }
    await this.fetchOverviewStatus()

  }

  async fetchOverviewStatus() {
    const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;

    let uatPreRequisiteDate = null, uatCrdentialCompletionStatus = 0, prodCrdentialCompletionStatus = 0

    if (application) {
      let data = await getIntegrationStatus({
        email: application.email,
        netsclickAppID: application.nc_app_id
      })
    
      let netsclickAppID = application.nc_app_id
      let uatInputObject = {
        env: ENV_UAT,
        netsclickAppID: netsclickAppID,
      }
      let prodInputObject = {
        env: ENV_PROD,
        netsclickAppID: netsclickAppID,
      }
      let uatVkeyValue = await getVkeyAssetsDetails(uatInputObject)
      let uatMidtid = await getMidTidDetails(uatInputObject)
      let uatSftp = 0

      let prodVkeyValue = await getVkeyAssetsDetails(prodInputObject)
      let prodMidtid = await getMidTidDetails(prodInputObject)
      let prodSftp = 0
      let prodMapHpp = await getMAPHPPKeys(prodInputObject)

      uatVkeyValue = uatVkeyValue.length > 0 ? 1 : null
      uatMidtid = uatMidtid.length == 1 && uatMidtid[0].status == 'A' ? 1 : 0

      prodVkeyValue = prodVkeyValue.length > 0 ? 1 : 0
      prodMidtid = prodMidtid.length == 1 && prodMidtid[0].status == 'A' ? 1 : 0
      prodMapHpp = prodMapHpp.length == 1 && prodMapHpp[0].status == 'A' ? 1 : 0

      let uatCredentialsStatus, prodCredentialsStatus = null
      let materialStatus = data && data.length >= 1 ? data[0] : null
      materialStatus = materialStatus && materialStatus.length >= 0 ? materialStatus[0] : null
      let signerStatus = data && data.length >= 1 ? data[1] : null
      if (signerStatus) {
        uatCredentialsStatus = signerStatus.find(signers => signers.ENV_PROFILE == ENV_UAT)
        prodCredentialsStatus = signerStatus.find(signers => signers.ENV_PROFILE == ENV_PROD)
      }
      uatCrdentialCompletionStatus =  uatVkeyValue + uatMidtid  
      prodCrdentialCompletionStatus =  prodMapHpp + prodMidtid + prodVkeyValue

      if(uatCredentialsStatus && uatCredentialsStatus.APP_TYPE == 'P'){
        if(uatCredentialsStatus.SFTP_STATUS){
           uatSftp = await getSFTPDetails(uatInputObject)
           prodSftp = await getSFTPDetails(prodInputObject) 

          uatSftp = uatSftp && uatSftp.sftpData ? uatSftp.sftpData : null
          prodSftp = prodSftp && prodSftp.sftpData ? prodSftp.sftpData : null
          uatSftp = uatSftp.length == 1 && uatSftp[0].status == 'A' ? 1 : 0
          prodSftp = prodSftp.length == 1 && prodSftp[0].status == 'A' ? 1 : 0
          uatCrdentialCompletionStatus = uatCrdentialCompletionStatus + uatSftp
          prodCrdentialCompletionStatus = prodCrdentialCompletionStatus + prodSftp
        } else {
          uatCrdentialCompletionStatus = uatCrdentialCompletionStatus >= 1 ? uatCrdentialCompletionStatus + 1 : uatCrdentialCompletionStatus
          prodCrdentialCompletionStatus = prodCrdentialCompletionStatus >=1 ? prodCrdentialCompletionStatus +1 : prodCrdentialCompletionStatus
        }
      }

      uatPreRequisiteDate = moment(application.updated_at).add(14, 'days').format('DD/MM/YYYY')
      let prodPreRequisiteDate = uatCredentialsStatus && uatCredentialsStatus.APP_TYPE == 'P' ? uatCredentialsStatus.UPDATED_AT : null
      prodPreRequisiteDate = prodPreRequisiteDate ? moment(prodPreRequisiteDate).add(7, 'days').format('DD/MM/YYYY') : null
      this.setState({
        selectedApp: application,
        uatCredentialsInfo: uatCredentialsStatus,
        prodCredentialsInfo: prodCredentialsStatus,
        materialInfo: materialStatus,
        uatPreRequisiteDueDate: uatPreRequisiteDate,
        prodPreRequsitieDueDate : prodPreRequisiteDate,
        uatCrdentialCompletionStatus: uatCrdentialCompletionStatus,
        prodCrdentialCompletionStatus: prodCrdentialCompletionStatus,
        fetched : true

      })
    }
  }
  redirectPage(pageId) {
    navigate(`/product/netsclick/${pageId}`, { state: this.state })
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }
  render() {
    let { fetched, materialInfo, uatCredentialsInfo, prodCredentialsInfo, uatPreRequisiteDueDate, prodPreRequsitieDueDate,  uatCrdentialCompletionStatus, prodCrdentialCompletionStatus } = this.state
    uatCrdentialCompletionStatus = uatCrdentialCompletionStatus == 0 ? 'Not ready' : uatCrdentialCompletionStatus <= 2  ?  'Partially ready. Please download credentials that are ready.' : "All ready. Please download all credentials to complete integration."
    prodCrdentialCompletionStatus = prodCrdentialCompletionStatus == 0 ? 'Not ready' : prodCrdentialCompletionStatus <= 3 ? 'Partially ready. Please download credentials that are ready.' : "All ready. Please download all credentials to complete integration."
    let prodCredentialsClassname = prodCrdentialCompletionStatus.includes('All ready') ? "successMessage" : ''
    let uatCredentialsClassname = uatCrdentialCompletionStatus.includes('All ready') ? "successMessage" : ''

    if (!fetched) {
      return <div class="loading"></div>
    }
    return (
      <Layout>
        <Banner title={`NETS Click`} />
        <div className='leftAligned'>

          <FormGroup row>
            <Col sm={9}>
              <h4><b>Welcome to NETS Click developer portal page.</b></h4>
            </Col>
            <Merchantcard />
          </FormGroup>
          <table class="customTable">
            <thead>
              <tr>
                <th colSpan={2}>Steps to start NETS Click integration</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className='hyperlink' onClick={() => this.redirectPage('materials')}> <u> Step 1 <br /> Material</u></div>
                </td>
                <td> Download Integration guide and specs</td>
                <td>
                  {
                    !materialInfo || materialInfo.materials == null ? <div> Step 1
                      <div className='errorMessage'>Not yet viewed</div>
                      <div className='errorMessage'>Please download material to start integration</div>
                    </div> : <div className='successMessage'><b>Viewed on {moment(materialInfo['materials']).format('DD/MM/YYYY')}</b></div>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <div>  Step 2<br /> Security Checklist<p className='font-size-small'>(Feature will be available soon)</p></div>
                </td>
                <td><div>
                  This feature is coming soon</div>
                  <div>For now, please contact your Sales/RM to complete the checklist which is<br /> mandatory before Production Credentials will be released. </div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td rowSpan={2}>
                  <div className='hyperlink' onClick={e => this.redirectPage('uatSetupCredentials')}>
                    <u> Step 3<br /> UAT Setup/ Credentials</u>
                  </div>
                </td>
                <td rowSpan={2}> Upload pre-requisites required for  UAT setup. And download <br />UAT Credentials to start integration.</td>
                <td>
                  {!uatCredentialsInfo || uatCredentialsInfo.length === 0 ? (
                    <>
                      <div>Merchant's Pre-requisites</div>
                      <div className='errorMessage'><small> Pending Submission. Please submit to avoid delay to integration.<br />Due Date : { uatPreRequisiteDueDate ? uatPreRequisiteDueDate : ''}</small></div>
                    </>
                  ) : (
                    <>
                      {uatCredentialsInfo && uatCredentialsInfo.APP_TYPE === 'T' ? (
                        <div className='errorMessage'> Saved but not submitted</div>
                      ) : (
                        <div className='successMessage'> Submitted</div>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  UAT Credentials :<br /> <div className={uatCredentialsClassname}>{uatCrdentialCompletionStatus}</div>
                </td>
              </tr>

              <tr className={ uatCredentialsInfo && uatCredentialsInfo.APP_TYPE === 'P' ? '' : 'disabledBtn' }>
                <td rowSpan={2}>
                  <div className='hyperlink' onClick={e => this.redirectPage('prodSetupCredentials')}>
                    <u> Step 4<br /> PROD Setup/ Credentials</u>
                  </div>
                </td>
                <td rowSpan={2}>Upload pre-requisites required for  PROD setup. And download <br />PROD Credentials to start integration.</td>
                <td>
                  {!prodCredentialsInfo || prodCredentialsInfo.length === 0 ? (
                    <div> <div>Merchant's Pre-requisites</div> {
                    prodPreRequsitieDueDate ? 
                   
                    <div className='errorMessage'><small>Pending Submission. Please submit to avoid delay to integration.<br />Due Date : {prodPreRequsitieDueDate}</small></div>
                 : <div className='errorMessage leftAligned'>-</div>
                   } </div>
                  ) : (
                    <>
                      { prodCredentialsInfo && prodCredentialsInfo.APP_TYPE === 'T' ? (
                        <div className='errorMessage'>Saved but not submitted</div>
                      ) : (
                        <div className='successMessage'>Submitted</div>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  PROD Credentials : <br /> <div className={prodCredentialsClassname}>{prodCrdentialCompletionStatus}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Layout>
    )
  }

}
